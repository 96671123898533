import {
  Flex,
  getSharedBorderStyle,
  Navigation,
  getMediaQueryForBelowBreakpoint,
  NavigationItemProps,
  NavigationItemChildProps,
  NavigationItemWithChildrenProps,
} from "@taxbit-private/cosmic";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import BannerNotification from "./notifications/BannerNotification";
import {
  useGetCaseStatus,
  isCaseStatusInProgress,
} from "../api/endpoints/cases/caseStatusGetApi";
import { CaseStatus } from "../api/endpoints/cases/caseStatusGetApiTypes";
import { useGetFiles } from "../api/endpoints/sources/filesGetApi";
import {
  isSourceStatusInProgress,
  isStatusDeleteInProgress,
} from "../api/endpoints/sources/utils/sourceStatus";
import { caseRouteParamsSchema } from "../pages/case-default/CaseIndexPage";
import useDartsStore from "../store/useDartsStore";
import { useGetBannerMessage } from "../utils/getBannerMessage";
import getSidebarNavWidth from "../utils/getSideNavBarWidth";
import useFailedTaxCalcsToast from "../utils/hooks/useFailedTaxCalcsToast";
import useFailedTransferDetectionToast from "../utils/hooks/useFailedTransferDetectionToast";
import { useFlags } from "../utils/hooks/useFlags";
import useRequiredParams from "../utils/hooks/useRequiredParams";

export type DashboardNavItem = Pick<
  NavigationItemProps,
  "label" | "trackingId" | "iconName" | "href"
>;
export type DashboardNavItemChild = Pick<
  NavigationItemChildProps,
  "label" | "trackingId" | "href"
>;

const AppNavigation: React.FC = () => {
  const { isCaseSummaryEnabled, isFileConverterEnabled } = useFlags();
  const { t } = useTranslation("case");
  const { getBannerMessage } = useGetBannerMessage();
  const NAV_ITEMS = useMemo(
    () => [
      ...(isCaseSummaryEnabled
        ? [
            {
              label: t("Case Summary"),
              trackingId: "case-summary",
              iconName: "user",
              href: "case-summary",
            },
          ]
        : []),
      {
        label: t("Transactions", { ns: "transactions" }),
        trackingId: "transactions",
        iconName: "book-open",
        href: "transactions",
      },
      {
        label: t("Sources", { ns: "sources" }),
        trackingId: "source",
        iconName: "link",
        href: "sources/generic-csv",
      },
      ...(isFileConverterEnabled
        ? [
            {
              label: t("File Converter", { ns: "sources" }),
              trackingId: "file-converter",
              iconName: "file",
              children: [
                {
                  label: t("Uploader", { ns: "sources" }),
                  trackingId: "file-converter-upload-tab",
                  href: "file-converter/upload",
                },
                {
                  label: t("File Review", { ns: "sources" }),
                  trackingId: "file-converter-review-tab",
                  href: "file-converter/review",
                },
              ],
            },
          ]
        : []),
      {
        label: t("Reports", { ns: "reports" }),
        trackingId: "reports",
        iconName: "file-text",
        href: "report-list",
      },
      {
        label: t("Link Transactions"),
        trackingId: "link-transactions",
        iconName: "globe",
        href: "link-transactions",
      },
      {
        label: t("Settings"),
        trackingId: "case-settings",
        iconName: "settings",
        href: "case-settings",
      },
    ],
    [isCaseSummaryEnabled, isFileConverterEnabled, t]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { caseId } = useRequiredParams(caseRouteParamsSchema);

  const addBanner = useDartsStore((state) => state.setBanner);
  const clearBanner = useDartsStore((state) => state.clearBanners);
  const isMenuVisible = useDartsStore((state) => state.isMenuVisible);
  const toggleMenu = useDartsStore((state) => state.toggleMenu);

  const { data: caseTaxCalc, isLoading: isCaseStatusLoading } =
    useGetCaseStatus(caseId);
  const { data: sources, isLoading: isSourcesLoading } = useGetFiles(caseId);

  const createNavigationItem = useCallback(
    ({ href, ...item }: DashboardNavItem | DashboardNavItemChild) => ({
      ...item,
      isActive: href ? location.pathname.includes(`/${href}`) : false,
      onClick: () => {
        if (href) {
          navigate(href);
        }
        if (isMenuVisible) {
          toggleMenu();
        }
      },
    }),
    [location.pathname, navigate, isMenuVisible, toggleMenu]
  );

  const completeModuleNavigationItems = useMemo(
    () =>
      NAV_ITEMS.map((item) => {
        if (!!item.children && Array.isArray(item.children)) {
          return {
            ...item,
            children: item.children.map((child) => createNavigationItem(child)),
          } as NavigationItemWithChildrenProps;
        } else {
          return createNavigationItem(item) as NavigationItemProps;
        }
      }),
    [createNavigationItem, NAV_ITEMS]
  );

  useFailedTaxCalcsToast();

  useFailedTransferDetectionToast();

  // Controls Case Status Banner
  useEffect(() => {
    if (!isSourcesLoading && !isCaseStatusLoading) {
      const calcsInProgress =
        caseTaxCalc &&
        isCaseStatusInProgress(caseTaxCalc.caseStatus as CaseStatus);
      const sourcesInProgress = sources
        ? sources.filter(
            (source) =>
              isSourceStatusInProgress(source.status) ||
              isStatusDeleteInProgress(source.status)
          )
        : [];
      if (sourcesInProgress.length > 0 || calcsInProgress) {
        addBanner(caseId, {
          message: getBannerMessage(sourcesInProgress),
          shouldShowSpinner: true,
        });
      } else {
        clearBanner();
      }
    }
  }, [
    addBanner,
    caseId,
    caseTaxCalc,
    clearBanner,
    sources,
    isSourcesLoading,
    isCaseStatusLoading,
    getBannerMessage,
  ]);

  return (
    <Flex>
      <NavWrapper
        direction="column"
        padding="xxl m m none"
        gap="xl"
        isMenuVisible={isMenuVisible}
      >
        <Flex direction="column" gap="l" padding="xl none none none">
          <Navigation items={completeModuleNavigationItems} />
        </Flex>
      </NavWrapper>
      <NonNavContent isMenuVisible={isMenuVisible}>
        <FixedNonNavContent>
          <BannerNotification />
        </FixedNonNavContent>
        <Outlet />
      </NonNavContent>
    </Flex>
  );
};

export default AppNavigation;

const NavWrapper = styled(Flex).withConfig({
  shouldForwardProp: (propName) => propName !== "isMenuVisible",
})<{
  isMenuVisible?: boolean;
}>(
  ({ theme, isMenuVisible }) => `
  background-color: ${theme.color.white};
  border-right: ${getSharedBorderStyle(theme)};
  width: ${getSidebarNavWidth(theme)};
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 2;
  overflow: auto;
  ${getMediaQueryForBelowBreakpoint(theme, "tablet")} {
     ${isMenuVisible ? "display: flex;" : "display: none;"}
  }
`
);

const NonNavContent = styled.div.withConfig<{
  isMenuVisible?: boolean;
}>({
  shouldForwardProp: (propName) => !["isMenuVisible"].includes(propName),
})(
  ({ theme, isMenuVisible }) => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${theme.color.gray0};
  width: 100%;
  margin-top: ${theme.sizing.xl};
  margin-left: ${getSidebarNavWidth(theme)};

 ${getMediaQueryForBelowBreakpoint(theme, "tablet")} {
     margin-left: ${
       isMenuVisible
         ? `calc(${getSidebarNavWidth(theme)} + ${theme.spacing.m})`
         : 0
     };


  }
`
);

const FixedNonNavContent = styled.div(
  ({ theme }) => `
  position: sticky;
  top: ${theme.sizing.xl};
  background-color: ${theme.color.white};
  z-index: 1;
`
);
